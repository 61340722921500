import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { FiGithub, FiExternalLink } from 'react-icons/fi';
import PortfolioSection from './PortfolioSection';
import { ArrowRight, Mail } from "lucide-react";

const ProjectsSection = () => {
    // Scroll to top when component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const featuredProjects = [
        {
            title: "Swiggy UI Clone | Razorpay",
            description: "Responsive UI clone of Swiggy with Razorpay payment gateway. Using Create React App, Tailwind CSS .",
            technologies: ["React", "Redux Toolkit", "Tailwind CSS","Razorpay API"],
            image: "https://i.ibb.co/XzyjvCt/THUMB-FUDSPOT.jpg",
            github: "https://github.com/Sreelesh-a/fud-spot",
            live: "https://fudspot.netlify.app/"
        }
    ];

    const handleGetInTouch = () => {
        const mailtoLink = `mailto:connect@sreelesh.in?subject=${encodeURIComponent("Let's Discuss Your Project")}&body=${encodeURIComponent("Hi Sreelesh,\n\nI'd like to discuss a project with you.")}`;
        window.location.href = mailtoLink;
    };

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
            {/* Hero Section */}
            <section className="relative overflow-hidden py-20">
                {/* Background Elements */}
                <div className="absolute inset-0 -z-10">
                    <div className="absolute inset-0 bg-gradient-to-br from-green-50 to-blue-50 dark:from-green-900/20 dark:to-blue-900/20" />
                    <motion.div 
                        animate={{ 
                            scale: [1, 1.2, 1],
                            rotate: [0, 45, 0]
                        }}
                        transition={{ duration: 20, repeat: Infinity }}
                        className="absolute -top-1/2 -right-1/2 w-full h-full bg-gradient-to-br from-green-400/10 to-blue-500/10 blur-3xl"
                    />
                </div>

                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="max-w-4xl mx-auto text-center">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="space-y-6"
                        >
                            <h1 className="text-4xl sm:text-6xl font-bold text-gray-900 dark:text-white">
                                Creative Portfolio
                            </h1>
                            <p className="text-lg text-gray-600 dark:text-gray-300">
                                Explore my latest projects and creative works, showcasing a blend of 
                                development expertise and design innovation.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* Featured Projects */}
            <section className="py-20 relative">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="space-y-16"
                    >
                        {featuredProjects.map((project, index) => (
                            <motion.div
                                key={project.title}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                                className="group relative bg-white dark:bg-gray-800 rounded-2xl overflow-hidden shadow-xl"
                            >
                                <div className="grid md:grid-cols-2 gap-8 p-8">
                                    {/* Project Image */}
                                    <div className="relative overflow-hidden rounded-xl">
                                        <motion.img
                                            whileHover={{ scale: 1.05 }}
                                            transition={{ duration: 0.3 }}
                                            src={project.image}
                                            alt={project.title}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>

                                    {/* Project Info */}
                                    <div className="space-y-6">
                                        <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                                            {project.title}
                                        </h3>
                                        <p className="text-gray-600 dark:text-gray-300">
                                            {project.description}
                                        </p>
                                        
                                        {/* Technologies */}
                                        <div className="flex flex-wrap gap-2">
                                            {project.technologies.map((tech) => (
                                                <span
                                                    key={tech}
                                                    className="px-3 py-1 text-sm rounded-full bg-green-100 dark:bg-green-900/30 
                                                             text-green-600 dark:text-green-400"
                                                >
                                                    {tech}
                                                </span>
                                            ))}
                                        </div>

                                        {/* Links */}
                                        <div className="flex gap-4">
                                            <motion.a
                                                whileHover={{ scale: 1.05 }}
                                                href={project.github}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center gap-2 px-4 py-2 rounded-full bg-gray-100 dark:bg-gray-700
                                                         text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 
                                                         transition-all duration-300"
                                            >
                                                <FiGithub className="w-5 h-5" />
                                                <span>Source Code</span>
                                            </motion.a>
                                            <motion.a
                                                whileHover={{ scale: 1.05 }}
                                                href={project.live}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center gap-2 px-4 py-2 rounded-full bg-green-500
                                                         text-white hover:bg-green-600 transition-all duration-300"
                                            >
                                                <FiExternalLink className="w-5 h-5" />
                                                <span>Live Demo</span>
                                            </motion.a>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </section>

            {/* Portfolio Grid */}
            <PortfolioSection />

            {/* Contact CTA */}
            <section className="py-20">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="max-w-3xl mx-auto text-center space-y-8"
                    >
                        <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 dark:text-white">
                            Have a project in mind?
                        </h2>
                        <p className="text-lg text-gray-600 dark:text-gray-300">
                            Let's collaborate and create something amazing together.
                        </p>
                        <div className="text-center mt-12">
                            <motion.button
                                onClick={handleGetInTouch}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="px-8 py-4 rounded-full bg-gradient-to-r from-green-500 to-blue-500 
                                         text-white font-medium shadow-lg hover:shadow-xl 
                                         transform hover:-translate-y-0.5 transition-all duration-300
                                         flex items-center gap-2 mx-auto"
                            >
                                <span>Get in Touch</span>
                                <Mail className="w-4 h-4" />
                            </motion.button>
                        </div>
                    </motion.div>
                </div>
            </section>
        </div>
    );
};

export default ProjectsSection; 