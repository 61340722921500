import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PortfolioSubSection from './PortfolioSubSection';
import { collection, getDocs } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { getDesignPortfolio, getPortfolio } from '../../appStore/profileSlice';
import { db } from '../../firebase';

const SkeletonCard = () => (
    <motion.div 
        className="relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 shadow-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
    >
        {/* Shimmer Effect Overlay */}
        <div className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite] 
                        bg-gradient-to-r from-transparent via-white/20 to-transparent" />
        
        {/* Skeleton Content */}
        <div className="aspect-w-16 aspect-h-9">
            <div className="w-full h-full bg-gray-200 dark:bg-gray-700" />
        </div>
        <div className="p-6 space-y-3">
            <div className="h-6 w-2/3 bg-gray-200 dark:bg-gray-700 rounded" />
            <div className="h-4 w-full bg-gray-200 dark:bg-gray-700 rounded" />
            <div className="h-4 w-3/4 bg-gray-200 dark:bg-gray-700 rounded" />
        </div>
    </motion.div>
);

const PortfolioSection = () => {
    const [togglePortfolio, setTogglePortfolio] = useState(false);
    const [portfolioJson, setPortfolioJson] = useState(null);
    const [designPortfolioJson, setDesignPortfolioJson] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const getPortfolioData = async () => {
            const portfolio = await getDocs(collection(db, "portfolio"));
            const designPortfolio = await getDocs(collection(db, "design_portfolio"));
            
            const gettingDesignPortfolioJson = designPortfolio.docs.map((doc) => doc.data());
            const gettingPortfolioJson = portfolio.docs.map((doc) => doc.data());
            
            setPortfolioJson(gettingPortfolioJson);
            setDesignPortfolioJson(gettingDesignPortfolioJson);
        };

        getPortfolioData();
    }, []);

    useEffect(() => {
        if (portfolioJson) {
            dispatch(getPortfolio(portfolioJson));
        }
        if (designPortfolioJson) {
            dispatch(getDesignPortfolio(designPortfolioJson));
        }
    }, [portfolioJson, designPortfolioJson, dispatch]);

    const portfolioData = useSelector((store) => store.profile.portfolio);
    const designPortfolioData = useSelector((store) => store.profile.designPortfolio);

    const PortfolioDisplay = () => {
        const isLoadingDev = !portfolioData;
        const isLoadingDesign = !designPortfolioData;
        const skeletonCount = 6;

        if ((!togglePortfolio && isLoadingDev) || (togglePortfolio && isLoadingDesign)) {
            return (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {[...Array(skeletonCount)].map((_, index) => (
                        <SkeletonCard key={index} />
                    ))}
                </div>
            );
        }

        return (
            <AnimatePresence mode="wait">
                <motion.div 
                    key={togglePortfolio ? 'design' : 'dev'}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
                >
                    {!togglePortfolio ? (
                        portfolioData?.map((data) => (
                            <motion.div
                                key={data?.title}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <PortfolioSubSection data={data} />
                            </motion.div>
                        ))
                    ) : (
                        designPortfolioData?.map((data) => (
                            <motion.div
                                key={data?.sn}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <PortfolioSubSection data={data} />
                            </motion.div>
                        ))
                    )}
                </motion.div>
            </AnimatePresence>
        );
    };

    return (
        <section className="py-20 relative overflow-hidden">
            {/* Background Elements */}
            <div className="absolute inset-0 -z-10">
                <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800" />
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,#22c55e,transparent_70%)]" />
            </div>

            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-6xl mx-auto space-y-12">
                    {/* Header */}
                    <div className="text-center space-y-6">
                        <motion.span 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="inline-block text-lg text-green-500 dark:text-green-400 font-medium"
                        >
                            Portfolio
                        </motion.span>
                        <motion.h2 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="text-4xl sm:text-6xl font-bold text-gray-900 dark:text-white"
                        >
                            Take a closer look at what I've built
                        </motion.h2>

                        {/* Toggle Buttons */}
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="flex justify-center gap-4 pt-4"
                        >
                            <button
                                onClick={() => setTogglePortfolio(false)}
                                className={`px-8 py-3 rounded-full font-medium transition-all duration-300
                                    ${!togglePortfolio 
                                        ? 'bg-green-500 text-white shadow-lg' 
                                        : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'}`}
                            >
                                Development
                            </button>
                            <button
                                onClick={() => setTogglePortfolio(true)}
                                className={`px-8 py-3 rounded-full font-medium transition-all duration-300
                                    ${togglePortfolio 
                                        ? 'bg-green-500 text-white shadow-lg' 
                                        : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'}`}
                            >
                                Design
                            </button>
                        </motion.div>
                    </div>

                    {/* Portfolio Grid */}
                    <div className="pt-8">
                        <PortfolioDisplay />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioSection;
