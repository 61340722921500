import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
    Code, Layout, BookOpen, Briefcase, Award,
    MonitorSmartphone, GitBranch, Github, Box,
    Paintbrush, Video, PenTool, Film, Linkedin
} from 'lucide-react';

const mockData = {
    profile: {
        name: "Sreelesh",
        location: "Kozhikode",
        role: "Web Developer & Designer",
        image: "https://i.ibb.co/Wv0w6PTW/Sreelesh-logo-compresses.webp",
        bio: [
            "I'm Sreelesh, from Kozhikode. A passionate self-taught developer and designer with a keen eye for detail and a love for creating seamless digital experiences.",
            "Through consistent self-learning and dedication, I've developed a diverse skill set that spans both development and design. I believe in the power of continuous learning and stay committed to exploring new technologies daily."
        ],
        socialLinks: [
            { icon: Github, link: "https://github.com/Sreelesh-a", name: "GitHub" },
            // { icon: Globe, link: "https://sreelesh.in", name: "Portfolio" },
            { icon: Linkedin, link: "https://www.linkedin.com/in/sreelesha/", name: "LinkedIn" }
        ],
        stats: [
            { label: "Experience", value: "2+ Years" },
            { label: "Projects", value: "500+" },
            { label: "Technologies", value: "15+" }
        ]
    },
    experience: [
        {
            role: "Creative Designer",
            company: "Incheon Motors Pvt Ltd",
            location: "Aluva",
            period: "Oct 2022 - Present",
            description: "Working as a creative designer, contributing to various design projects and digital initiatives."
        }
    ],
    education: [
        {
            degree: "Bachelor of Commerce",
            institution: "SARBTM Govt. College",
            period: "2019-2022"
        },
        {
            degree: "Higher Secondary Commerce",
            institution: "GVHSS, Payyoli",
            period: "2017-2019"
        }
    ],
    skills: {
        development: [
            "React.js",
            "HTML",
            "CSS",
            "JavaScript",
            "Tailwind CSS",
            "Redux Toolkit",
            "Custom Hooks",
            "Context API"
        ],
        design: [
            { icon: Paintbrush, name: "Photoshop" },
            { icon: Video, name: "After Effects" },
            { icon: PenTool, name: "Illustrator" },
            { icon: Film, name: "Premiere Pro" }
        ],
        tools: [
            { icon: MonitorSmartphone, name: "VS Code" },
            { icon: GitBranch, name: "Git" },
            { icon: Github, name: "GitHub" },
            { icon: Box, name: "Redux DevTools" }
        ]
    }
};

const AboutSection = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
            {/* Hero Section */}
            <section className="relative pt-20 pb-16 overflow-hidden">
                {/* Background Elements */}
                <div className="absolute inset-0 -z-10">
                    <div className="absolute inset-0 bg-gradient-to-br from-green-50 to-blue-50 dark:from-green-900/20 dark:to-blue-900/20" />
                    <motion.div 
                        animate={{ 
                            scale: [1, 1.2, 1],
                            rotate: [0, 45, 0]
                        }}
                        transition={{ duration: 20, repeat: Infinity }}
                        className="absolute -top-1/2 -right-1/2 w-full h-full bg-gradient-to-br from-green-400/10 to-blue-500/10 blur-3xl"
                    />
                </div>

                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="max-w-7xl mx-auto">
                        <div className="grid lg:grid-cols-2 gap-12 items-center">
                            {/* Profile Image Section */}
                            <motion.div
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5 }}
                                className="relative mx-auto lg:mx-0"
                            >
                                <div className="relative w-64 h-64 md:w-80 md:h-80">
                                    {/* Decorative Elements */}
                                    <motion.div
                                        animate={{ 
                                            rotate: [0, 360],
                                            scale: [1, 1.1, 1]
                                        }}
                                        transition={{ 
                                            duration: 20,
                                            repeat: Infinity,
                                            ease: "linear"
                                        }}
                                        className="absolute inset-0 rounded-full bg-gradient-to-r from-green-400/20 to-blue-500/20 blur-2xl"
                                    />
                                    <motion.div
                                        animate={{ 
                                            rotate: [360, 0],
                                            scale: [1, 1.2, 1]
                                        }}
                                        transition={{ 
                                            duration: 15,
                                            repeat: Infinity,
                                            ease: "linear"
                                        }}
                                        className="absolute inset-0 rounded-full bg-gradient-to-l from-green-400/20 to-blue-500/20 blur-2xl"
                                    />
                                    
                                    {/* Profile Container */}
                                    <div className="absolute inset-4 bg-gradient-to-br from-green-400 to-blue-500 rounded-full p-1">
                                        <div className="w-full h-full rounded-full bg-white dark:bg-gray-800 p-1 backdrop-blur-xl relative overflow-hidden">
                                            {/* Profile Image */}
                                            <img 
                                                src={mockData.profile.image}
                                                alt={mockData.profile.name}
                                                className="w-full h-full object-cover rounded-full relative z-10 
                                                           transform transition-transform duration-500 hover:scale-105"
                                            />
                                            
                                            {/* Animated Background */}
                                            <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-gray-50 dark:from-gray-800 dark:to-gray-900" />
                                            <div className="absolute inset-0 bg-grid-slate-200/20 dark:bg-grid-slate-50/10" />
                                        </div>
                                    </div>
                                </div>

                                {/* Social Links */}
                                <motion.div 
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.5 }}
                                    className="flex justify-center mt-6 gap-4"
                                >
                                    {mockData.profile.socialLinks.map((social, index) => (
                                        <motion.a
                                            key={index}
                                            href={social.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="p-3 rounded-full bg-white/90 dark:bg-gray-800/90 shadow-lg 
                                                     hover:shadow-xl transition-all duration-300
                                                     text-gray-600 dark:text-gray-300 hover:text-green-500 
                                                     dark:hover:text-green-400"
                                        >
                                            <social.icon className="w-5 h-5" />
                                        </motion.a>
                                    ))}
                                </motion.div>
                            </motion.div>

                            {/* About Content */}
                            <motion.div
                                initial={{ opacity: 0, x: 50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                                className="space-y-8"
                            >
                                <div className="space-y-4">
                                    <motion.span 
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        className="inline-block px-4 py-1.5 rounded-full text-sm font-medium
                                                 bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400"
                                    >
                                        {mockData.profile.role}
                                    </motion.span>
                                    <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 dark:text-white">
                                        About Me
                                    </h1>
                                </div>

                                <div className="space-y-6 text-lg text-gray-600 dark:text-gray-300">
                                    {mockData.profile.bio.map((paragraph, index) => (
                                        <motion.p
                                            key={index}
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: index * 0.1 }}
                                        >
                                            {paragraph}
                                        </motion.p>
                                    ))}
                                </div>

                                {/* Quick Stats */}
                                <motion.div 
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.5 }}
                                    className="grid grid-cols-2 sm:grid-cols-3 gap-6"
                                >
                                    {mockData.profile.stats.map((stat, index) => (
                                        <div key={stat.label} className="text-center">
                                            <div className="text-3xl font-bold text-green-500 dark:text-green-400">
                                                {stat.value}
                                            </div>
                                            <div className="text-sm text-gray-600 dark:text-gray-400">
                                                {stat.label}
                                            </div>
                                        </div>
                                    ))}
                                </motion.div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Experience Section */}
            <section className="py-16">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="max-w-7xl mx-auto space-y-12"
                    >
                        {/* Experience */}
                        <div className="space-y-8">
                            <div className="flex items-center space-x-4">
                                <Briefcase className="w-8 h-8 text-green-500" />
                                <h2 className="text-3xl font-bold text-gray-900 dark:text-white">Experience</h2>
                            </div>
                            {mockData.experience.map((exp, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.2 }}
                                    className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-lg"
                                >
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{exp.role}</h3>
                                    <p className="text-green-500 font-medium">{exp.company}</p>
                                    <p className="text-gray-600 dark:text-gray-400">{exp.location} | {exp.period}</p>
                                    <p className="mt-2 text-gray-600 dark:text-gray-300">{exp.description}</p>
                                </motion.div>
                            ))}
                        </div>

                        {/* Education */}
                        <div className="space-y-8">
                            <div className="flex items-center space-x-4">
                                <BookOpen className="w-8 h-8 text-green-500" />
                                <h2 className="text-3xl font-bold text-gray-900 dark:text-white">Education</h2>
                            </div>
                            <div className="grid sm:grid-cols-2 gap-6">
                                {mockData.education.map((edu, index) => (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, y: 20 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        viewport={{ once: true }}
                                        transition={{ delay: index * 0.2 }}
                                        className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-lg"
                                    >
                                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{edu.degree}</h3>
                                        <p className="text-green-500">{edu.institution}</p>
                                        <p className="text-gray-600 dark:text-gray-400">{edu.period}</p>
                                    </motion.div>
                                ))}
                            </div>
                        </div>

                        {/* Skills */}
                        <div className="space-y-8">
                            <div className="flex items-center space-x-4">
                                <Award className="w-8 h-8 text-green-500" />
                                <h2 className="text-3xl font-bold text-gray-900 dark:text-white">Skills</h2>
                            </div>
                            
                            {/* Development Skills */}
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                className="space-y-6"
                            >
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center gap-2">
                                    <Code className="w-5 h-5" />
                                    Development
                                </h3>
                                <div className="flex flex-wrap gap-3">
                                    {mockData.skills.development.map((skill, index) => (
                                        <motion.span
                                            key={skill}
                                            initial={{ opacity: 0, scale: 0.5 }}
                                            whileInView={{ opacity: 1, scale: 1 }}
                                            viewport={{ once: true }}
                                            transition={{ delay: index * 0.1 }}
                                            className="px-4 py-2 rounded-full bg-green-100 dark:bg-green-900/30 
                                                     text-green-600 dark:text-green-400"
                                        >
                                            {skill}
                                        </motion.span>
                                    ))}
                                </div>
                            </motion.div>

                            {/* Design Skills */}
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                className="space-y-6"
                            >
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center gap-2">
                                    <Layout className="w-5 h-5" />
                                    Design
                                </h3>
                                <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                                    {mockData.skills.design.map((tool, index) => (
                                        <motion.div
                                            key={tool.name}
                                            initial={{ opacity: 0, y: 20 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            viewport={{ once: true }}
                                            transition={{ delay: index * 0.1 }}
                                            className="bg-white dark:bg-gray-800 rounded-xl p-4 shadow-lg
                                                     flex flex-col items-center gap-2"
                                        >
                                            <tool.icon className="w-8 h-8 text-green-500" />
                                            <span className="text-gray-700 dark:text-gray-300">{tool.name}</span>
                                        </motion.div>
                                    ))}
                                </div>
                            </motion.div>

                            {/* Development Tools */}
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                className="space-y-6"
                            >
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Development Tools</h3>
                                <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                                    {mockData.skills.tools.map((tool, index) => (
                                        <motion.div
                                            key={tool.name}
                                            initial={{ opacity: 0, y: 20 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            viewport={{ once: true }}
                                            transition={{ delay: index * 0.1 }}
                                            className="bg-white dark:bg-gray-800 rounded-xl p-4 shadow-lg
                                                     flex flex-col items-center gap-2"
                                        >
                                            <tool.icon className="w-8 h-8 text-green-500" />
                                            <span className="text-gray-700 dark:text-gray-300">{tool.name}</span>
                                        </motion.div>
                                    ))}
                                </div>
                            </motion.div>
                        </div>
                    </motion.div>
                </div>
            </section>
        </div>
    );
};

export default AboutSection;
