import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from 'next-themes';
import { Link, useLocation } from 'react-router-dom';
import { FiSun, FiMoon, FiMenu, FiX } from 'react-icons/fi';
import { Sun, Moon } from "lucide-react";

// Update the mock data import path and add default nav items
const NAV_ITEMS = [
    {
        name: "Home",
        path: "/",
        icon: "🏠",
    },
    {
        name: "Projects",
        path: "/projects",
        icon: "💼",
    },
    {
        name: "About",
        path: "/about",
        icon: "👨‍💻",
    },
    {
        name: "Contact",
        path: "/contact",
        icon: "📬",
    }
];

const Header = () => {
    const [mounted, setMounted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { theme, setTheme } = useTheme();
    const location = useLocation();

    // After mounting, we can show the theme toggle
    useEffect(() => setMounted(true), []);

    return (
        <motion.header
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            className="fixed w-full top-0 z-50"
        >
            {/* Glassmorphism background with better contrast */}
            <div className="absolute inset-0 bg-white/90 dark:bg-gray-900/90 backdrop-blur-xl border-b border-gray-300 dark:border-gray-600" />

            <nav className="relative mx-auto px-4 sm:px-6 lg:px-8 py-4">
                <div className="max-w-7xl mx-auto">
                    <div className="flex items-center justify-between">
                        {/* Logo/Name */}
                        <Link to="/">
                            <motion.div 
                                whileHover={{ scale: 1.05 }}
                                className="relative group"
                            >
                                <div className="text-2xl font-bold bg-gradient-to-r from-green-500 to-blue-500 
                                              bg-clip-text text-transparent relative z-10">
                                    Sreelesh
                                    <div className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r 
                                                  from-green-500 to-blue-500 group-hover:w-full 
                                                  transition-all duration-300" />
                                </div>
                                <motion.div
                                    className="absolute -inset-1 bg-gradient-to-r from-green-500/20 to-blue-500/20 
                                             rounded-lg blur-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                                    style={{ zIndex: 0 }}
                                />
                            </motion.div>
                        </Link>

                        {/* Desktop Navigation */}
                        <div className="hidden md:flex items-center space-x-2">
                            <div className="bg-gray-100/80 dark:bg-gray-800/80 backdrop-blur-lg rounded-full p-1.5 flex items-center">
                                {NAV_ITEMS.map((item) => (
                                    <motion.div
                                        key={item.path}
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <Link
                                            to={item.path}
                                            className={`px-4 py-2 rounded-full transition-all duration-300
                                                ${location.pathname === item.path 
                                                    ? 'bg-gradient-to-r from-blue-600 to-purple-600 text-white shadow-lg' 
                                                    : 'text-gray-800 dark:text-gray-200 hover:bg-gray-200/80 dark:hover:bg-gray-700/80'
                                                }`}
                                        >
                                            {item.name}
                                        </Link>
                                    </motion.div>
                                ))}
                            </div>

                            {/* Theme Toggle with better contrast */}
                            {mounted && (
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                                    className="p-3 rounded-full bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200
                                             hover:bg-gray-200 dark:hover:bg-gray-700 shadow-md
                                             transition-all duration-300"
                                    aria-label={theme === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}
                                >
                                    <motion.div
                                        initial={false}
                                        animate={{ rotate: theme === 'dark' ? 180 : 0 }}
                                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                                    >
                                        {theme === 'dark' ? (
                                            <FiSun className="w-5 h-5" />
                                        ) : (
                                            <FiMoon className="w-5 h-5" />
                                        )}
                                    </motion.div>
                                </motion.button>
                            )}
                        </div>

                        {/* Mobile Menu Button */}
                        <motion.button
                            whileTap={{ scale: 0.9 }}
                            onClick={() => setIsOpen(!isOpen)}
                            className="md:hidden p-3 rounded-full bg-white/50 dark:bg-gray-800/50 backdrop-blur-lg
                                     text-gray-700 dark:text-gray-300"
                        >
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key={isOpen ? 'close' : 'menu'}
                                    initial={{ rotate: -90, opacity: 0 }}
                                    animate={{ rotate: 0, opacity: 1 }}
                                    exit={{ rotate: 90, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    {isOpen ? (
                                        <FiX className="w-6 h-6" />
                                    ) : (
                                        <FiMenu className="w-6 h-6" />
                                    )}
                                </motion.div>
                            </AnimatePresence>
                        </motion.button>
                    </div>
                </div>

                {/* Mobile Navigation */}
                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.2 }}
                            className="md:hidden mt-4"
                        >
                            <div className="rounded-2xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg p-4 space-y-2 shadow-xl">
                                {NAV_ITEMS.map((item, index) => (
                                    <motion.div
                                        key={item.path}
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: index * 0.1 }}
                                    >
                                        <Link
                                            to={item.path}
                                            onClick={() => setIsOpen(false)}
                                            className={`block px-4 py-3 rounded-xl transition-all duration-300
                                                ${location.pathname === item.path 
                                                    ? 'bg-gradient-to-r from-blue-600 to-purple-600 text-white' 
                                                    : 'text-gray-700 dark:text-gray-300 hover:bg-white/50 dark:hover:bg-gray-700/50'
                                                }`}
                                        >
                                            {item.name}
                                        </Link>
                                    </motion.div>
                                ))}
                                
                                {/* Mobile Theme Toggle */}
                                {mounted && (
                                    <motion.button
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: NAV_ITEMS.length * 0.1 }}
                                        onClick={() => {
                                            setTheme(theme === 'dark' ? 'light' : 'dark');
                                            setIsOpen(false);
                                        }}
                                        className="w-full px-4 py-3 rounded-xl text-left text-gray-700 dark:text-gray-300
                                                 hover:bg-white/50 dark:hover:bg-gray-700/50 transition-all duration-300
                                                 flex items-center space-x-3"
                                    >
                                        {theme === 'dark' ? (
                                            <>
                                                <FiSun className="w-5 h-5" />
                                                <span>Light Mode</span>
                                            </>
                                        ) : (
                                            <>
                                                <FiMoon className="w-5 h-5" />
                                                <span>Dark Mode</span>
                                            </>
                                        )}
                                    </motion.button>
                                )}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </nav>
        </motion.header>
    );
};

export default Header;
