import React from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { ChevronLeft, ChevronRight } from "lucide-react";

// Skeleton Card Component
const SkeletonCard = () => (
  <div className="px-2 md:px-4">
    <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 md:p-6 shadow-lg">
      <div className="flex items-center space-x-4">
        <div className="relative w-10 h-10 md:w-12 md:h-12 rounded-full 
                      bg-gray-200 dark:bg-gray-700 animate-pulse" />
        <div className="flex-1 space-y-2">
          <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse w-2/3" />
          <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded animate-pulse w-1/2" />
        </div>
      </div>
    </div>
  </div>
);

// Loading Carousel Component
const LoadingCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {[1, 2, 3, 4, 5].map((index) => (
        <SkeletonCard key={index} />
      ))}
    </Slider>
  );
};

// Custom arrow components
const CustomArrow = ({ direction, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`hidden md:flex absolute top-1/2 transform -translate-y-1/2 z-10
                 ${direction === 'prev' ? 'left-2' : 'right-2'}
                 bg-white dark:bg-gray-800 p-2 rounded-full shadow-lg
                 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-200
                 text-gray-600 dark:text-gray-300`}
    >
      {direction === 'prev' ? (
        <ChevronLeft className="w-5 h-5" />
      ) : (
        <ChevronRight className="w-5 h-5" />
      )}
    </button>
  );
};

const ClientsCarousel = () => {
  const clientData = useSelector((store) => store.client);
  const isLoading = !clientData || clientData.length === 0;

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 500,
    cssEase: "linear",
    pauseOnHover: true,
    initialDelay: 300,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
          autoplaySpeed: 500
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          autoplaySpeed: 500
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          autoplaySpeed: 500
        }
      }
    ]
  }; 

  if (isLoading) {
    return (
      <div className="py-10 px-4 md:px-8 relative">
        <LoadingCarousel />
      </div>
    );
  }

  return (
    <div className="py-10 px-4 md:px-8 relative">
      <Slider {...settings}>
        {clientData?.map((client) => (
          <div
            key={client?.name}
            className="px-2 md:px-4"
          >
            <div
              className="bg-white dark:bg-gray-800 rounded-2xl p-4 md:p-6 shadow-lg hover:shadow-xl 
                       transition-all duration-300"
            >
              <div className="flex items-center space-x-4">
                <div className="relative w-10 h-10 md:w-12 md:h-12 rounded-full overflow-hidden">
                  <img 
                    src={client?.profile_link} 
                    alt={client?.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <h3 className="text-base md:text-lg font-semibold text-gray-900 dark:text-white">
                    {client?.name}
                  </h3>
                  <p className="text-xs md:text-sm text-gray-500 dark:text-gray-400">
                    Content Creator
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ClientsCarousel;
