import React from "react";
import { motion } from "framer-motion";
import { ExternalLink } from 'lucide-react';

const PortfolioSubSection = ({ data }) => {
    const { title, thumb_link, description, redirect_link } = data;
    
    const handleClick = () => {
        if (redirect_link) {
            window.open(redirect_link, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <motion.div
            whileHover={{ y: -5 }}
            className="relative group overflow-hidden rounded-2xl bg-white dark:bg-gray-800 shadow-lg"
            transition={{ duration: 0.2 }}
        >
            <div className="aspect-w-16 aspect-h-9 relative overflow-hidden">
                <img 
                    src={thumb_link} 
                    alt={title}
                    className="w-full h-full object-cover"
                />
                {redirect_link && (
                    <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 
                                  flex items-center justify-center transition-opacity duration-300
                                  z-20 pointer-events-none group-hover:pointer-events-auto">
                        <motion.button
                            onClick={handleClick}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="px-6 py-3 bg-green-500 hover:bg-green-600 text-white 
                                     rounded-full font-medium shadow-lg flex items-center gap-2
                                     transition-colors duration-300 relative z-30"
                        >
                            View Project <ExternalLink className="w-5 h-5" />
                        </motion.button>
                    </div>
                )}
            </div>
            
            {title && (
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent 
                               opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                    <div className="absolute bottom-0 p-6 space-y-2">
                        <h3 className="text-2xl font-bold text-white">
                            {title}
                        </h3>
                        <p className="text-gray-200 text-sm line-clamp-2">
                            {description}
                        </p>
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default PortfolioSubSection;
