import React from 'react';
import { motion } from 'framer-motion';
import { Mail, ArrowRight } from 'lucide-react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="relative bg-white dark:bg-gray-900">
            {/* Gradient Divider */}
            <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-green-400 to-blue-500" />

            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="py-12">
                    {/* Main Footer Content */}
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
                        {/* Left Side - Copyright */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            className="text-gray-600 dark:text-gray-400"
                        >
                            <p className="text-sm">
                                © {currentYear} sreelesh.in. All rights reserved.
                            </p>
                        </motion.div>

                        {/* Center - Email */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.1 }}
                            className="flex items-center space-x-2 text-gray-600 dark:text-gray-400"
                        >
                            <Mail className="w-4 h-4" />
                            <a 
                                href="mailto:connect@sreelesh.in"
                                className="text-sm hover:text-green-500 dark:hover:text-green-400 transition-colors"
                            >
                                connect@sreelesh.in
                            </a>
                        </motion.div>

                        {/* Right Side - Hire Now Button */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.2 }}
                        >
                            <motion.a
                                href="mailto:connect@sreelesh.in?subject=Work Inquiry"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="inline-flex items-center px-6 py-3 rounded-full 
                                         bg-gradient-to-r from-green-500 to-blue-500 
                                         text-white font-medium shadow-lg 
                                         hover:shadow-xl transition-all duration-300"
                            >
                                Hire Now
                                <ArrowRight className="w-4 h-4 ml-2" />
                            </motion.a>
                        </motion.div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer; 