import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { TITLE_DESCRIPTION } from "../../utils/mockDate";
import { FiArrowDown, FiGithub, FiLinkedin } from 'react-icons/fi';
import { ArrowRight } from "lucide-react";
import { 
    SiReact, SiTailwindcss, SiAdobephotoshop, 
    SiAdobeillustrator, SiJavascript, SiRedux, 
    SiGit, SiFirebase 
} from 'react-icons/si';

const skillIcons = [
    { Icon: SiReact, color: '#61DAFB', name: 'React' },
    { Icon: SiTailwindcss, color: '#06B6D4', name: 'Tailwind' },
    { Icon: SiAdobephotoshop, color: '#31A8FF', name: 'Photoshop' },
    { Icon: SiAdobeillustrator, color: '#FF9A00', name: 'Illustrator' },
    { Icon: SiJavascript, color: '#F7DF1E', name: 'JavaScript' },
    { Icon: SiRedux, color: '#764ABC', name: 'Redux' },
    { Icon: SiGit, color: '#F05032', name: 'Git' },
    { Icon: SiFirebase, color: '#FFCA28', name: 'Firebase' }
];

const MainContainer = () => {
    const navigate = useNavigate();

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
                delayChildren: 0.3
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 0.5 }
        }
    };

    return (
        <section className="relative min-h-screen overflow-hidden flex items-center">
            {/* Subtle Background */}
            <div className="absolute inset-0 -z-10">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,#22c55e,transparent_70%)]" />
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,#15803d,transparent_70%)]" />
                <div className="absolute inset-0 backdrop-blur-[100px]" />
            </div>

            <div className="container mx-auto px-4 relative pt-20">
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="max-w-6xl mx-auto"
                >
                    <div className="grid lg:grid-cols-5 gap-8 items-center">
                        {/* Main Content - Takes 3 columns */}
                        <div className="lg:col-span-3 space-y-12">
                            <motion.div variants={itemVariants}>
                                <span className="px-4 py-1.5 rounded-full text-sm font-medium bg-green-100/50 dark:bg-green-900/50
                                               border border-green-200 dark:border-green-800 text-green-700 dark:text-green-300 inline-block">
                                    Open to Work
                                </span>
                            </motion.div>

                            <motion.div variants={itemVariants} className="space-y-4">
                                <h1 className="text-6xl sm:text-7xl font-bold text-gray-900 dark:text-white tracking-tight">
                                    Sreelesh A
                                </h1>
                                <p className="text-lg sm:text-xl font-normal text-gray-600 dark:text-gray-300 leading-relaxed max-w-2xl">
                                    {TITLE_DESCRIPTION}
                                </p>
                            </motion.div>

                            <motion.div 
                                variants={itemVariants}
                                className="flex flex-wrap gap-4 pt-4"
                            >
                                <motion.a
                                    href="mailto:connect@sreelesh.in?subject=Work Inquiry"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="px-8 py-4 rounded-full bg-gradient-to-r from-green-500 to-blue-500 
                                             text-white font-medium shadow-lg hover:shadow-xl 
                                             transform hover:-translate-y-0.5 transition-all duration-300
                                             flex items-center gap-2"
                                >
                                    <span>Hire Me</span>
                                    <ArrowRight className="w-4 h-4" />
                                </motion.a>
                                <motion.button
                                    onClick={() => navigate('/projects')}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="px-8 py-4 rounded-full bg-white dark:bg-gray-800 
                                             text-gray-900 dark:text-white font-medium 
                                             shadow-lg hover:shadow-xl border border-gray-200 dark:border-gray-700
                                             transform hover:-translate-y-0.5 transition-all duration-300
                                             flex items-center gap-2"
                                >
                                    <span>Explore My Work</span>
                                    <ArrowRight className="w-4 h-4" />
                                </motion.button>
                            </motion.div>

                            {/* Social Links */}
                            <motion.div 
                                variants={itemVariants}
                                className="flex gap-4 pt-2"
                            >
                                <a href="https://github.com/Sreelesh-a" target="_blank" rel="noopener noreferrer"
                                   className="p-2.5 rounded-full bg-white/5 backdrop-blur-sm hover:bg-white/10 
                                            border border-gray-200/20 dark:border-gray-700/30 transition-all duration-200
                                            text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                    <FiGithub className="w-5 h-5" />
                                </a>
                                <a href="https://www.linkedin.com/in/sreelesha/" target="_blank" rel="noopener noreferrer"
                                   className="p-2.5 rounded-full bg-white/5 backdrop-blur-sm hover:bg-white/10 
                                            border border-gray-200/20 dark:border-gray-700/30 transition-all duration-200
                                            text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                    <FiLinkedin className="w-5 h-5" />
                                </a>
                            </motion.div>
                        </div>

                        {/* Decorative Right Section */}
                        <motion.div 
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.8, delay: 0.5 }}
                            className="lg:col-span-2 hidden lg:block"
                        >
                            <div className="relative h-[600px] w-full">
                                {/* Floating Skill Icons */}
                                {skillIcons.map((skill, index) => (
                                    <motion.div
                                        key={index}
                                        className="absolute"
                                        initial={{ 
                                            x: Math.random() * 300,
                                            y: Math.random() * 400
                                        }}
                                        animate={{
                                            x: [
                                                Math.random() * 300,
                                                Math.random() * 300,
                                                Math.random() * 300
                                            ],
                                            y: [
                                                Math.random() * 400,
                                                Math.random() * 400,
                                                Math.random() * 400
                                            ],
                                            rotate: [0, 360],
                                            scale: [1, 1.2, 1]
                                        }}
                                        transition={{
                                            duration: 20 + Math.random() * 10,
                                            repeat: Infinity,
                                            repeatType: "reverse",
                                            ease: "linear"
                                        }}
                                    >
                                        <motion.div
                                            whileHover={{ scale: 1.2 }}
                                            className="relative group"
                                        >
                                            <div className="absolute -inset-2 bg-gradient-to-r from-green-500 to-blue-500 
                                                          rounded-full opacity-0 group-hover:opacity-100 blur transition-all duration-300" />
                                            <div className="relative bg-white dark:bg-gray-800 rounded-full p-3 shadow-lg">
                                                <skill.Icon 
                                                    className="w-8 h-8" 
                                                    style={{ color: skill.color }}
                                                />
                                            </div>
                                            
                                            {/* Tooltip */}
                                            <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 
                                                          bg-gray-900 text-white px-2 py-1 rounded text-sm
                                                          opacity-0 group-hover:opacity-100 transition-opacity duration-300
                                                          whitespace-nowrap">
                                                {skill.name}
                                            </div>
                                        </motion.div>
                                    </motion.div>
                                ))}

                                {/* Central Glow Effect */}
                                <motion.div
                                    animate={{
                                        scale: [1, 1.2, 1],
                                        opacity: [0.3, 0.5, 0.3]
                                    }}
                                    transition={{
                                        duration: 8,
                                        repeat: Infinity,
                                        ease: "easeInOut"
                                    }}
                                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                                               w-64 h-64 bg-gradient-to-r from-green-400/30 to-blue-500/30 rounded-full blur-3xl"
                                />

                                {/* Connection Lines */}
                                <svg className="absolute inset-0 w-full h-full" style={{ zIndex: -1 }}>
                                    <motion.path
                                        stroke="url(#gradient)"
                                        strokeWidth="0.5"
                                        fill="none"
                                        strokeLinecap="round"
                                        animate={{
                                            d: [
                                                "M100,200 Q200,100 300,200",
                                                "M100,250 Q200,150 300,250",
                                                "M100,200 Q200,100 300,200"
                                            ]
                                        }}
                                        transition={{
                                            duration: 10,
                                            repeat: Infinity,
                                            ease: "easeInOut"
                                        }}
                                    />
                                    <defs>
                                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stopColor="#22C55E" stopOpacity="0.2" />
                                            <stop offset="100%" stopColor="#3B82F6" stopOpacity="0.2" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                        </motion.div>
                    </div>
                </motion.div>

                {/* Scroll Indicator */}
                <motion.div 
                    className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
                    animate={{ y: [0, 10, 0] }}
                    transition={{ duration: 2, repeat: Infinity }}
                >
                    <FiArrowDown className="w-5 h-5 text-green-600 dark:text-green-400" />
                </motion.div>
            </div>
        </section>
    );
};

export default MainContainer;


