module.exports = {
    TITLE_DESCRIPTION: "I'm a Developer with a designer's eye, focused on creating easy-to-use and impactful user experiences.",
    
    // Theme configuration
    THEME_CONFIG: {
        light: {
            primary: "#2563eb",
            background: "#ffffff",
            text: "#1f2937",
            secondary: "#6b7280",
            accent: "#3b82f6",
            card: "#f3f4f6",
            border: "#e5e7eb"
        },
        dark: {
            primary: "#60a5fa",
            background: "#111827",
            text: "#f9fafb",
            secondary: "#9ca3af",
            accent: "#3b82f6",
            card: "#1f2937",
            border: "#374151"
        }
    },

    // Enhanced animation configurations
    ANIMATIONS: {
        navBar: {
            initial: { y: -20, opacity: 0 },
            animate: { y: 0, opacity: 1 },
            transition: { duration: 0.6, ease: "easeOut" }
        },
        themeSwitcher: {
            initial: { rotate: 0 },
            animate: { rotate: 360 },
            transition: { duration: 0.5, ease: "easeInOut" }
        },
        pageTransition: {
            initial: { opacity: 0, y: 20 },
            animate: { opacity: 1, y: 0 },
            exit: { opacity: 0, y: -20 },
            transition: { duration: 0.5 }
        },
        hoverScale: {
            scale: 1.05,
            transition: { duration: 0.2 }
        },
        cardHover: {
            rest: { scale: 1, y: 0 },
            hover: { scale: 1.03, y: -5 }
        }
    },

    // Navigation items with icons
    NAV_ITEMS: [
        {
            name: "Home",
            path: "/",
            icon: "home-icon",
            animation: { delay: 0.1 }
        },
        {
            name: "Projects",
            path: "/projects",
            icon: "projects-icon",
            animation: { delay: 0.2 }
        },
        {
            name: "About",
            path: "/about",
            icon: "about-icon",
            animation: { delay: 0.3 }
        },
        {
            name: "Contact",
            path: "/contact",
            icon: "contact-icon",
            animation: { delay: 0.4 }
        }
    ],

    // Enhanced featured technologies with more details
    FEATURED_TECHNOLOGIES: [
        {
            name: "React",
            icon: "react-icon",
            color: "#61DAFB",
            experience: "3+ years",
            projects: 15,
            description: "Building scalable web applications"
        },
        {
            name: "Next.js",
            icon: "nextjs-icon",
            color: "#000000",
            experience: "2+ years",
            projects: 8,
            description: "Server-side rendering specialist"
        },
        {
            name: "Framer Motion",
            icon: "framer-icon",
            color: "#BB4B96",
            experience: "1+ year",
            projects: 10,
            description: "Crafting smooth animations"
        }
    ],

    // Enhanced achievements with animations
    ACHIEVEMENTS: [
        {
            number: "50+",
            label: "Projects Completed",
            icon: "project-icon",
            animation: {
                initial: { scale: 0 },
                animate: { scale: 1 },
                transition: { type: "spring", stiffness: 100 }
            }
        },
        {
            number: "95%",
            label: "Client Satisfaction",
            icon: "satisfaction-icon",
            animation: {
                initial: { scale: 0 },
                animate: { scale: 1 },
                transition: { type: "spring", stiffness: 100, delay: 0.2 }
            }
        },
        {
            number: "5+",
            label: "Years Experience",
            icon: "experience-icon",
            animation: {
                initial: { scale: 0 },
                animate: { scale: 1 },
                transition: { type: "spring", stiffness: 100, delay: 0.4 }
            }
        }
    ],

    // Enhanced testimonials with animations and layout
    TESTIMONIALS: [
        {
            quote: "Exceptional work and attention to detail",
            author: "John Doe",
            position: "CEO, Tech Corp",
            image: "/testimonials/john.jpg",
            rating: 5,
            animation: {
                initial: { x: -50, opacity: 0 },
                animate: { x: 0, opacity: 1 },
                transition: { duration: 0.5 }
            }
        },
        {
            quote: "Delivered beyond our expectations",
            author: "Jane Smith",
            position: "Product Manager, StartUp Inc",
            image: "/testimonials/jane.jpg",
            rating: 5,
            animation: {
                initial: { x: 50, opacity: 0 },
                animate: { x: 0, opacity: 1 },
                transition: { duration: 0.5, delay: 0.2 }
            }
        }
    ],

    // Social links with hover effects
    SOCIAL_LINKS: [
        {
            platform: "GitHub",
            url: "https://github.com/username",
            icon: "github-icon",
            hoverColor: "#333",
            animation: { delay: 0.1 }
        },
        {
            platform: "LinkedIn",
            url: "https://linkedin.com/in/username",
            icon: "linkedin-icon",
            hoverColor: "#0077b5",
            animation: { delay: 0.2 }
        },
        {
            platform: "Twitter",
            url: "https://twitter.com/username",
            icon: "twitter-icon",
            hoverColor: "#1DA1F2",
            animation: { delay: 0.3 }
        }
    ],

    // Loading states and animations
    LOADING_STATES: {
        initial: {
            spinner: {
                animate: { rotate: 360 },
                transition: { duration: 1, repeat: Infinity, ease: "linear" }
            },
            text: {
                animate: { opacity: [0.5, 1] },
                transition: { duration: 0.8, repeat: Infinity, repeatType: "reverse" }
            }
        }
    }
};


