import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { getClients } from "../../appStore/clientSlice";
import { db } from "../../firebase";
import ClientsCarousel from "./ClientsCarousel";

const ClientsSection = () => {
    const [clientJson, setClientJson] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const getClient = async () => {
            const client = await getDocs(collection(db, "clients"));
            const gettingClientJson = client.docs.map((doc) => doc.data());
            setClientJson(gettingClientJson);
        };

        getClient();
    }, []);

    useEffect(() => {
        if (clientJson) {
            dispatch(getClients(clientJson));
        }
    }, [clientJson, dispatch]);

    return (
        <section className="py-20 relative overflow-hidden">
            {/* Background Elements */}
            <div className="absolute inset-0 -z-10">
                <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800" />
                <motion.div 
                    animate={{ 
                        scale: [1, 1.2, 1],
                        rotate: [0, 90, 0]
                    }}
                    transition={{ duration: 20, repeat: Infinity }}
                    className="absolute -top-1/2 -right-1/2 w-full h-full bg-gradient-to-br from-green-400/10 to-emerald-500/10 blur-3xl"
                />
            </div>

            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="text-center space-y-4 mb-12"
                    >
                        <motion.span 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            className="text-green-500 dark:text-green-400 font-medium"
                        >
                            Clients
                        </motion.span>
                        <motion.h2 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            className="text-4xl sm:text-5xl font-bold text-gray-900 dark:text-white"
                        >
                            Trusted by Creators
                        </motion.h2>
                        <motion.p 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto"
                        >
                            Proud to collaborate with leading YouTube creators, crafting designs
                            that elevate their content.
                        </motion.p>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="relative"
                    >
                        <div className="absolute inset-0 bg-gradient-to-r from-gray-50 via-transparent to-gray-50 
                                      dark:from-gray-900 dark:via-transparent dark:to-gray-900 z-10 pointer-events-none" />
                        <ClientsCarousel />
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default ClientsSection;
