import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, Phone, Eye, EyeOff, ExternalLink } from 'lucide-react';

const ContactSection = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [showPhone, setShowPhone] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const mailtoLink = `mailto:connect@sreelesh.in?subject=${encodeURIComponent(formData.subject)}&body=${encodeURIComponent(`Name: ${formData.fullName}\n\nMessage:\n${formData.message}`)}`;
        window.location.href = mailtoLink;
    };

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
            {/* Hero Section */}
            <section className="relative pt-20 pb-16 overflow-hidden">
                {/* Background Elements */}
                <div className="absolute inset-0 -z-10">
                    <div className="absolute inset-0 bg-gradient-to-br from-green-50 to-blue-50 dark:from-green-900/20 dark:to-blue-900/20" />
                    <motion.div 
                        animate={{ 
                            scale: [1, 1.2, 1],
                            rotate: [0, 45, 0]
                        }}
                        transition={{ duration: 20, repeat: Infinity }}
                        className="absolute -top-1/2 -right-1/2 w-full h-full bg-gradient-to-br from-green-400/10 to-blue-500/10 blur-3xl"
                    />
                </div>

                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="max-w-4xl mx-auto text-center">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="space-y-6"
                        >
                            <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 dark:text-white">
                                Get in Touch
                            </h1>
                            <p className="text-lg text-gray-600 dark:text-gray-300">
                                Let's discuss your project and bring your ideas to life.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* Contact Information */}
            <section className="py-12">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid md:grid-cols-2 gap-12">
                            {/* Contact Info Cards */}
                            <div className="space-y-6">
                                {/* Email Card */}
                                <motion.div
                                    initial={{ opacity: 0, x: -50 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-lg"
                                >
                                    <div className="flex items-center space-x-4">
                                        <div className="p-3 bg-green-100 dark:bg-green-900/30 rounded-full">
                                            <Mail className="w-6 h-6 text-green-500" />
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                                Email
                                            </h3>
                                            <a 
                                                href="mailto:connect@sreelesh.in"
                                                className="text-green-500 hover:text-green-600 transition-colors"
                                            >
                                                connect@sreelesh.in
                                            </a>
                                        </div>
                                    </div>
                                </motion.div>

                                {/* Phone Card */}
                                <motion.div
                                    initial={{ opacity: 0, x: -50 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: 0.1 }}
                                    className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-lg"
                                >
                                    <div className="flex items-center space-x-4">
                                        <div className="p-3 bg-green-100 dark:bg-green-900/30 rounded-full">
                                            <Phone className="w-6 h-6 text-green-500" />
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                                Phone
                                            </h3>
                                            <div className="flex items-center space-x-3">
                                                <AnimatePresence mode="wait">
                                                    {showPhone ? (
                                                        <motion.span
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            exit={{ opacity: 0 }}
                                                            className="text-green-500"
                                                        >
                                                            +91 9074 286356
                                                        </motion.span>
                                                    ) : (
                                                        <motion.span
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            exit={{ opacity: 0 }}
                                                            className="text-gray-400 dark:text-gray-500"
                                                        >
                                                            Click to view
                                                        </motion.span>
                                                    )}
                                                </AnimatePresence>
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    onClick={() => setShowPhone(!showPhone)}
                                                    className="p-1.5 rounded-full bg-green-100 dark:bg-green-900/30
                                                             text-green-500 hover:bg-green-200 dark:hover:bg-green-900/50
                                                             transition-colors"
                                                >
                                                    {showPhone ? (
                                                        <EyeOff className="w-4 h-4" />
                                                    ) : (
                                                        <Eye className="w-4 h-4" />
                                                    )}
                                                </motion.button>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>

                            {/* Contact Form */}
                            <motion.div
                                initial={{ opacity: 0, x: 50 }}
                                animate={{ opacity: 1, x: 0 }}
                                className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-lg"
                            >
                                <form onSubmit={handleSubmit} className="space-y-6">
                                    <div>
                                        <label 
                                            htmlFor="fullName"
                                            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                                        >
                                            Full Name
                                        </label>
                                        <input
                                            type="text"
                                            id="fullName"
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleChange}
                                            required
                                            className="w-full px-4 py-3 rounded-xl bg-gray-50 dark:bg-gray-700
                                                     text-gray-900 dark:text-white border border-gray-200 
                                                     dark:border-gray-600 focus:ring-2 focus:ring-green-500
                                                     focus:border-transparent outline-none transition-all"
                                        />
                                    </div>

                                    <div>
                                        <label 
                                            htmlFor="subject"
                                            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                                        >
                                            Subject
                                        </label>
                                        <input
                                            type="text"
                                            id="subject"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            required
                                            className="w-full px-4 py-3 rounded-xl bg-gray-50 dark:bg-gray-700
                                                     text-gray-900 dark:text-white border border-gray-200 
                                                     dark:border-gray-600 focus:ring-2 focus:ring-green-500
                                                     focus:border-transparent outline-none transition-all"
                                        />
                                    </div>

                                    <div>
                                        <label 
                                            htmlFor="message"
                                            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                                        >
                                            Message
                                        </label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                            rows={4}
                                            className="w-full px-4 py-3 rounded-xl bg-gray-50 dark:bg-gray-700
                                                     text-gray-900 dark:text-white border border-gray-200 
                                                     dark:border-gray-600 focus:ring-2 focus:ring-green-500
                                                     focus:border-transparent outline-none transition-all"
                                        />
                                    </div>

                                    <motion.button
                                        type="submit"
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        className="w-full px-6 py-3 rounded-xl bg-gradient-to-r from-green-500 
                                                 to-blue-500 text-white font-medium shadow-lg hover:shadow-xl 
                                                 transition-all duration-300 flex items-center justify-center space-x-2"
                                    >
                                        <span>Send Message</span>
                                        <ExternalLink className="w-4 h-4" />
                                    </motion.button>
                                </form>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContactSection; 