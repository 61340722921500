import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/Home";
import { Provider } from "react-redux";
import store from "./appStore/store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./components/Header";
import ProjectsSection from "./components/subFolders/ProjectsSection";
import AboutSection from "./components/subFolders/AboutSection";
import Footer from "./components/subFolders/Footer";
import ContactSection from "./components/subFolders/ContactSection";

function App() {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: (
        <div className="min-h-screen bg-white dark:bg-gray-900">
          <Header />
          <main className="pt-16">
            <Home />
          </main>
          <Footer />
        </div>
      ),
    },
    {
      path: "/projects",
      element: (
        <div className="min-h-screen bg-white dark:bg-gray-900">
          <Header />
          <main className="pt-16">
            <ProjectsSection />
          </main>
          <Footer />
        </div>
      ),
    },
    {
      path: "/about",
      element: (
        <div className="min-h-screen bg-white dark:bg-gray-900">
          <Header />
          <main className="pt-16">
            <AboutSection />
          </main>
          <Footer />
        </div>
      ),
    },
    {
      path: "/contact",
      element: (
        <div className="min-h-screen bg-white dark:bg-gray-900">
          <Header />
          <main className="pt-16">
            <ContactSection />
          </main>
          <Footer />
        </div>
      ),
    },
  ]);

  return (
    <Provider store={store}>
      <RouterProvider router={appRouter} />
    </Provider>
  );
}

export default App;
