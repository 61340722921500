import React from "react";
import { motion } from "framer-motion";
import { FiCode, FiLayout, FiVideo, FiMonitor, FiDatabase, FiSmartphone } from "react-icons/fi";

const ServicesSection = () => {
    const services = [
        {
            id: '01',
            title: "Visual Design",
            description: "Creating stunning visual experiences",
            skills: [
                { name: "Creative Poster", icon: FiLayout },
                { name: "Motion Graphics", icon: FiVideo },
                { name: "UX/UI", icon: FiMonitor },
                { name: "Video Editing", icon: FiVideo }
            ]
        },
        {
            id: '02',
            title: "Development",
            description: "Building robust web applications",
            skills: [
                { name: "React.JS", icon: FiCode },
                { name: "JavaScript", icon: FiCode },
                { name: "Tailwind", icon: FiLayout },
                { name: "MongoDB", icon: FiDatabase },
                { name: "Node.JS", icon: FiSmartphone }
            ]
        }
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 0.5 }
        }
    };

    return (
        <section className="py-20 relative overflow-hidden">
            {/* Background Elements */}
            <div className="absolute inset-0 -z-10">
                <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800" />
                <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-gradient-to-b from-green-400/5 to-transparent dark:from-green-400/10" />
                <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-gradient-to-t from-green-400/5 to-transparent dark:from-green-400/10" />
            </div>

            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    className="space-y-16"
                >
                    {/* Header */}
                    <motion.div variants={itemVariants} className="text-center space-y-4">
                        <span className="text-green-500 dark:text-green-400 font-medium">Services</span>
                        <h2 className="text-4xl sm:text-5xl font-bold text-gray-900 dark:text-white">
                            Available services that I can work on
                        </h2>
                    </motion.div>

                    {/* Services Grid */}
                    <div className="grid gap-12">
                        {services.map((service) => (
                            <motion.div
                                key={service.id}
                                variants={itemVariants}
                                className="relative"
                            >
                                <div className="flex flex-col lg:flex-row gap-8">
                                    {/* Service Title */}
                                    <div className="lg:w-1/3 space-y-4">
                                        <div className="flex items-baseline gap-2">
                                            <span className="text-4xl font-bold text-green-500">
                                                {service.id}.
                                            </span>
                                            <h3 className="text-3xl font-bold text-gray-900 dark:text-white">
                                                {service.title}
                                            </h3>
                                        </div>
                                        <p className="text-gray-600 dark:text-gray-300">
                                            {service.description}
                                        </p>
                                    </div>

                                    {/* Skills Grid */}
                                    <div className="lg:w-2/3">
                                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                                            {service.skills.map((skill, index) => (
                                                <motion.div
                                                    key={skill.name}
                                                    whileHover={{ scale: 1.02 }}
                                                    className="group relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 
                                                             p-6 shadow-lg transition-all duration-300
                                                             hover:shadow-xl hover:bg-green-50 dark:hover:bg-gray-700"
                                                >
                                                    <div className="absolute top-0 right-0 -mt-4 -mr-4 h-16 w-16 
                                                                  bg-green-500 opacity-10 rotate-45 transform 
                                                                  scale-150 group-hover:scale-200 transition-transform" />
                                                    
                                                    <div className="relative space-y-4">
                                                        <skill.icon className="h-8 w-8 text-green-500" />
                                                        <h4 className="text-xl font-semibold text-gray-900 dark:text-white">
                                                            {skill.name}
                                                        </h4>
                                                    </div>
                                                </motion.div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>
            </div>
        </section>
    );
};

export default ServicesSection;
